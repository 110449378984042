import { createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "container block block-alone" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "search" }
const _hoisted_4 = { class: "table" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "decorate-box" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "row" }
const _hoisted_9 = {
  class: "upload-box",
  style: {"width":"100%"}
}
const _hoisted_10 = { class: "reveal-file" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { style: {"width":"100%"} }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { style: {"width":"100%"} }
const _hoisted_16 = { class: "dialog-btn-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_batchPayroll = _resolveComponent("batchPayroll")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[14] || (_cache[14] = _createElementVNode("div", { class: "header" }, [
        _createElementVNode("div", { class: "row header-info-box" }, [
          _createElementVNode("div", { class: "title" }, "被派遣单位"),
          _createElementVNode("div")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: "请输入单位名称",
            class: "input search-input",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event)),
            onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)), ["enter"]))
          }, null, 544), [
            [_vModelText, _ctx.searchText]
          ]),
          _createElementVNode("button", {
            class: "btn",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)))
          }, "搜索"),
          _createElementVNode("button", {
            class: "btn-white",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onReset && _ctx.onReset(...args)))
          }, "重置")
        ]),
        _cache[12] || (_cache[12] = _createElementVNode("div", null, null, -1))
      ]),
      _createVNode(_component_el_tabs, { "model-value": "detail" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_el_table, {
              data: _ctx.tableData,
              style: {"width":"100%"},
              "header-cell-style": {background: '#FBFBFD'},
              "header-row-style": { color: '#202536' },
              "row-style": { color: '#5E617D' },
              onSelectionChange: _ctx.handleSelectionChange
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  label: "企业名称",
                  width: "200px"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(scope.row.wesureInfo.serveCompanyName), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, { label: "行业" }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(scope.row.wesureInfo.industryName || '暂无'), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, { label: "视频" }, {
                  default: _withCtx((scope) => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.params.file, (item, index) => {
                      return (_openBlock(), _createElementBlock("p", {
                        style: {"color":"#1989FA"},
                        class: "point",
                        key: index
                      }, [
                        (/(.mp4|.flv|.mov|.wmv|.avi|.mpeg|.rmvb)$/.test(item))
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              class: "left-video-a",
                              style: {"color":"#1989FA","text-decoration":"none"},
                              target: "_blank",
                              href: item
                            }, "点击查看视频", 8, _hoisted_5))
                          : _createCommentVNode("", true),
                        (!/(.mp4|.flv|.mov|.wmv|.avi|.mpeg|.rmvb)$/.test(item))
                          ? (_openBlock(), _createBlock(_component_el_image, {
                              key: 1,
                              style: {"width":"100px","height":"50px"},
                              src: item,
                              fit: "cover",
                              "preview-src-list": [item],
                              "initial-index": 0
                            }, {
                              default: _withCtx(() => _cache[13] || (_cache[13] = [
                                _createElementVNode("template", null, [
                                  _createTextVNode(" 点击查看图片 ")
                                ], -1)
                              ])),
                              _: 2
                            }, 1032, ["src", "preview-src-list"]))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  width: "240px",
                  prop: "createTime",
                  label: "添加时间"
                }),
                _createVNode(_component_el_table_column, { label: "状态" }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", {
                        class: "decorate",
                        style: _normalizeStyle({background:scope.row.wesureInfo.auditStatus == 0 ? '#FFC53D' : scope.row.wesureInfo.auditStatus == 1 ? '#00D679' : '#FF7E68'})
                      }, null, 4),
                      _createElementVNode("p", null, _toDisplayString(scope.row.wesureInfo.auditStatus == 0 ? '未提交审核' : scope.row.wesureInfo.auditStatus == 1 ? '已通过' : scope.row.wesureInfo.auditStatus == 2 ? '驳回' : scope.row.wesureInfo.auditStatus == 3 ? '审核中' : ''), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  label: "备注",
                  prop: "remark"
                }),
                _createVNode(_component_el_table_column, { label: "操作" }, {
                  default: _withCtx((scope) => [
                    (scope.row.wesureInfo.auditStatus !== 3)
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 0,
                          style: {"color":"#FF7E68"},
                          class: "point",
                          onClick: ($event: any) => (
                                        _ctx.deleteDispatchExamine(scope.row.id)
                                    )
                        }, " 删除 ", 8, _hoisted_7))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data", "onSelectionChange"]),
            _createVNode(_component_el_pagination, {
              class: "pagination",
              currentPage: _ctx.pageNum,
              "onUpdate:currentPage": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.pageNum) = $event)),
              "page-size": _ctx.pageSize,
              "onUpdate:pageSize": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.pageSize) = $event)),
              "page-sizes": [10, 30, 50, 100],
              layout: "total, sizes, prev, pager, next, jumper",
              total: _ctx.total,
              onSizeChange: _ctx.handleSizeChange,
              onCurrentChange: _ctx.getPayrollList
            }, null, 8, ["currentPage", "page-size", "total", "onSizeChange", "onCurrentChange"])
          ])
        ]),
        _: 1
      }),
      (_ctx.isBatchPay)
        ? (_openBlock(), _createBlock(_component_batchPayroll, {
            key: 0,
            onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isBatchPay = false)),
            onComplete: _ctx.getPayrollList,
            isType: "payroll"
          }, null, 8, ["onComplete"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.centerDialogVisible,
      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.centerDialogVisible) = $event)),
      title: "新增被派遣单位",
      width: "642px",
      "destroy-on-close": "",
      center: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _cache[17] || (_cache[17] = _createElementVNode("div", { class: "name" }, [
            _createElementVNode("span", {
              class: "important",
              style: {"opacity":"-0.9"}
            }, "*"),
            _createTextVNode(" 上传视频 ")
          ], -1)),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_el_upload, {
              class: "upload-demo",
              action: _ctx.uploadAction,
              headers: {Authorization: _ctx.Authorization},
              "on-success": _ctx.fileSuccess,
              "show-file-list": false,
              "on-error": _ctx.fileEerror,
              accept: "image/jpeg,image/image/png,video/*"
            }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createElementVNode("button", {
                  class: "btn-white-blue",
                  style: {"width":"130px","background":"#1989FA","color":"#fff"}
                }, [
                  _createElementVNode("i", { class: "el-icon el-icon-camera" }),
                  _createTextVNode("选择视频文件 ")
                ], -1)
              ])),
              _: 1
            }, 8, ["action", "headers", "on-success", "on-error"]),
            _createElementVNode("div", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.revealFileList, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "file-i",
                  key: index,
                  onClick: ($event: any) => (_ctx.fileRemove(item.uid))
                }, [
                  _createTextVNode(_toDisplayString(item.name) + " ", 1),
                  _cache[16] || (_cache[16] = _createElementVNode("span", { class: "point" }, "×", -1))
                ], 8, _hoisted_11))
              }), 128))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _cache[18] || (_cache[18] = _createElementVNode("div", { class: "name" }, [
            _createElementVNode("span", { class: "important" }, "*"),
            _createTextVNode(" 被派遣单位 ")
          ], -1)),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_el_select, {
              modelValue: _ctx.companyId,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.companyId) = $event)),
              filterable: "",
              placeholder: "请选择派遣单位"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companyList, (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.id,
                    label: item.serveCompanyName,
                    value: item.id
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _cache[19] || (_cache[19] = _createElementVNode("div", { class: "name" }, [
            _createElementVNode("span", { class: "important" }, "*"),
            _createTextVNode(" 申请理由 ")
          ], -1)),
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_el_input, {
              class: "remarks-input",
              modelValue: _ctx.remarksInput,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.remarksInput) = $event)),
              type: "text",
              placeholder: "请输入申请理由"
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("button", {
            class: "btn-white",
            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.centerDialogVisible=false)),
            style: {"marginRight":"20px"}
          }, "取消"),
          _createElementVNode("button", {
            class: "btn",
            onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.addDispatchUnit && _ctx.addDispatchUnit(...args))),
            style: {"marginLength":"20px"}
          }, "提交审核")
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}