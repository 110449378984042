import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/xlsx/success.png'


const _hoisted_1 = { class: "process" }
const _hoisted_2 = {
  key: 0,
  class: "box-block form"
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = {
  class: "value",
  style: {"display":"flex","align-items":"center","flex":"auto"}
}
const _hoisted_6 = { style: {"color":"#1989fa"} }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "value" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "value" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "value" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "value" }
const _hoisted_17 = {
  key: 1,
  class: "form-error"
}
const _hoisted_18 = { class: "error-table" }
const _hoisted_19 = {
  key: 2,
  class: "box-block enclosure"
}
const _hoisted_20 = { class: "form-left" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { key: 1 }
const _hoisted_25 = { class: "link-box" }
const _hoisted_26 = ["onClick"]
const _hoisted_27 = { class: "form-right" }
const _hoisted_28 = { class: "info-enclosure-name parallel" }
const _hoisted_29 = { class: "info-right" }
const _hoisted_30 = { class: "info-people-num parallel" }
const _hoisted_31 = { class: "info-right" }
const _hoisted_32 = { class: "info-total-money parallel" }
const _hoisted_33 = { class: "info-right info-right-num" }
const _hoisted_34 = {
  class: "parallel",
  style: {"justify-content":"space-between"}
}
const _hoisted_35 = {
  key: 3,
  class: "box-block result"
}
const _hoisted_36 = { class: "result-info" }
const _hoisted_37 = { class: "info-enclosure-name parallel" }
const _hoisted_38 = { class: "info-right" }
const _hoisted_39 = { class: "info-people-num parallel" }
const _hoisted_40 = { class: "info-right" }
const _hoisted_41 = { class: "info-total-money parallel" }
const _hoisted_42 = { class: "info-right info-right-num" }
const _hoisted_43 = { class: "edit-popup-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_alertBox = _resolveComponent("alertBox")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_alertBox, {
      "data-title": "批量投保",
      isCloseType: true,
      onClose: _ctx.onCallback
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.whichStep === 0 ? 'active' : '', 'process-box'])
          }, _cache[7] || (_cache[7] = [
            _createElementVNode("div", { class: "no" }, [
              _createElementVNode("i", { class: "el-icon el-icon-check" })
            ], -1),
            _createElementVNode("span", null, "上传文件", -1)
          ]), 2),
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "arrow" }, [
            _createElementVNode("i", { class: "el-icon el-icon-arrow-right" })
          ], -1)),
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.whichStep === 1 ? 'active' : '', 'process-box'])
          }, _cache[8] || (_cache[8] = [
            _createElementVNode("div", { class: "no" }, " 2 ", -1),
            _createElementVNode("span", null, "确认信息", -1)
          ]), 2),
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "arrow" }, [
            _createElementVNode("i", { class: "el-icon el-icon-arrow-right" })
          ], -1)),
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.whichStep === 2 ? 'active' : '', 'process-box'])
          }, _cache[9] || (_cache[9] = [
            _createElementVNode("div", { class: "no" }, " 3 ", -1),
            _createElementVNode("span", null, "查看结果", -1)
          ]), 2)
        ]),
        (_ctx.whichStep === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_el_upload, {
                class: "upload-demo",
                drag: !_ctx.fileList.length,
                disabled: !!_ctx.fileList.length && !!_ctx.fileFormatErroe.length,
                "show-file-list": false,
                "file-list": _ctx.fileList,
                "before-upload": _ctx.onUpFile,
                "http-request": () => { },
                accept: ".xlsx",
                action: ""
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _cache[14] || (_cache[14] = _createElementVNode("div", { class: "key" }, [
                        _createElementVNode("span", { class: "important" }, "*"),
                        _createElementVNode("span", null, "投保数据")
                      ], -1)),
                      _createElementVNode("div", _hoisted_5, [
                        _cache[12] || (_cache[12] = _createElementVNode("button", { class: "btn import-btn" }, [
                          _createElementVNode("i", { class: "el-icon el-icon-upload2" }),
                          _createTextVNode("导入 ")
                        ], -1)),
                        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "tipsText" }, " 或拖拽至此处 ", -1)),
                        _createElementVNode("a", {
                          class: "down-templete",
                          style: {"position":"relative","z-index":"10"},
                          href: "https://bullet001.oss-cn-shanghai.aliyuncs.com/file/template/template_wesure.xlsx",
                          onClick: _withModifiers(() => {
                                    return;
                                }
                                    , ["stop"])
                        }, "模板下载"),
                        (_ctx.fileList.length)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass([_ctx.fileList[0].name ? 'fileInfo' : ''])
                            }, [
                              _createTextVNode(_toDisplayString(_ctx.fileList[0].name ? "" + "文件名" : "") + " ", 1),
                              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.fileList[0].name), 1),
                              _createElementVNode("span", {
                                class: _normalizeClass([_ctx.fileList[0].name ? 'fileDel' : '']),
                                onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onDeleteFile && _ctx.onDeleteFile(...args)), ["stop"]))
                              }, _toDisplayString(_ctx.fileList[0].name ? "×" : ""), 3)
                            ], 2))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _cache[15] || (_cache[15] = _createElementVNode("div", null, null, -1))
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _cache[16] || (_cache[16] = _createElementVNode("div", { class: "key" }, [
                        _createElementVNode("span", { class: "important" }, "*"),
                        _createElementVNode("span", null, "被派遣单位")
                      ], -1)),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_el_select, {
                          modelValue: _ctx.companyId,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.companyId) = $event)),
                          filterable: "",
                          onChange: _ctx.onSelectCompany,
                          placeholder: "请选择被派遣单位"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companyList, (item, index) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                label: item.serveCompanyName,
                                disabled: item.isBlacklist || item.isForbidden
                                        ,
                                value: item.id,
                                key: index
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.serveCompanyName), 1)
                                ]),
                                _: 2
                              }, 1032, ["label", "disabled", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "onChange"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _cache[17] || (_cache[17] = _createElementVNode("div", { class: "key" }, [
                        _createElementVNode("span", { class: "important" }, "*"),
                        _createElementVNode("span", null, "投保方案")
                      ], -1)),
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_el_select, {
                          modelValue: _ctx.planIndex,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.planIndex) = $event)),
                          filterable: "",
                          placeholder: "请选择投保方案",
                          onChange: _ctx.onSelectInsure
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.planList, (item, index) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                label: item.industryPlanName,
                                value: index,
                                key: index
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "onChange"])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _cache[18] || (_cache[18] = _createElementVNode("div", { class: "key" }, [
                        _createElementVNode("span", { class: "important" }, "*"),
                        _createElementVNode("span", null, "总金额")
                      ], -1)),
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(_component_el_input, {
                          placeholder: "上传表单后自动填充",
                          disabled: "",
                          name: "totalAmount",
                          value: (_ctx.userList.length * _ctx.planPrice).toFixed(2)
                                
                        }, null, 8, ["value"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _cache[19] || (_cache[19] = _createElementVNode("div", { class: "key" }, [
                        _createElementVNode("span", { class: "important" }, "*"),
                        _createElementVNode("span", null, "总人数")
                      ], -1)),
                      _createElementVNode("div", _hoisted_16, [
                        _createVNode(_component_el_input, {
                          placeholder: "上传表单后自动填充",
                          disabled: "",
                          name: "totalNumber",
                          value: _ctx.userList.length
                        }, null, 8, ["value"])
                      ])
                    ])
                  ]),
                  _createVNode(_component_el_button, {
                    class: "btn mini-btn",
                    type: "primary",
                    disabled: !_ctx.userList.length || !_ctx.planId || _ctx.errorList.length > 0
                    ,
                    onClick: _withModifiers(_ctx.nextStep, ["stop"])
                  }, {
                    default: _withCtx(() => _cache[20] || (_cache[20] = [
                      _createTextVNode("下一步")
                    ])),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ]),
                _: 1
              }, 8, ["drag", "disabled", "file-list", "before-upload"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.errorList.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              _createVNode(_component_el_alert, {
                title: `本次上传文件共发现${_ctx.errorList.length}处错误,请修改后重新上传`
                ,
                type: "error",
                center: "",
                "show-icon": ""
              }, null, 8, ["title"]),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_el_table, {
                  data: _ctx.errorList,
                  "header-row-style": { color: 'black' },
                  "row-style": { border: 'none' }
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, {
                      prop: "rowNum",
                      label: "行号",
                      width: "100px"
                    }),
                    _createVNode(_component_el_table_column, {
                      prop: "userName",
                      label: "姓名",
                      width: "100px"
                    }),
                    _createVNode(_component_el_table_column, {
                      prop: "idCard",
                      label: "身份证号",
                      width: "150px"
                    }),
                    _createVNode(_component_el_table_column, {
                      prop: "alreadyInsured",
                      label: "错误信息",
                      width: "100px"
                    }, {
                      default: _withCtx((scope) => [
                        _createElementVNode("p", null, _toDisplayString(scope.row.errorMsg), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["data"])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.whichStep === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _cache[21] || (_cache[21] = _createElementVNode("div", { class: "info-title" }, " 投保数据单 ", -1)),
                _createVNode(_component_el_scrollbar, { "max-height": "430px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table, {
                      data: _ctx.userList,
                      "row-class-name": _ctx.tableRowClassName,
                      style: {"width":"100%"},
                      "header-row-style": { color: '#202536' },
                      "row-style": { color: '#5E617D' },
                      "cell-style": {
                            height: '35px',
                            padding: '0'
                        }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_table_column, {
                          type: "index",
                          label: "序号",
                          width: "50"
                        }),
                        _createVNode(_component_el_table_column, {
                          width: "80",
                          label: "姓名"
                        }, {
                          default: _withCtx((scope) => [
                            (_ctx.editIndex !== scope.$index)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_21, _toDisplayString(scope.row.userName), 1))
                              : (_openBlock(), _createElementBlock("p", _hoisted_22, [
                                  _createVNode(_component_el_input, {
                                    placeholder: "姓名",
                                    modelValue: scope.row.userName,
                                    "onUpdate:modelValue": ($event: any) => ((scope.row.userName) = $event)
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                ]))
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_table_column, {
                          prop: "idCard",
                          label: "身份证号",
                          width: "170"
                        }, {
                          default: _withCtx((scope) => [
                            (_ctx.editIndex !== scope.$index)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_23, _toDisplayString(scope.row.idCard), 1))
                              : (_openBlock(), _createElementBlock("p", _hoisted_24, [
                                  _createVNode(_component_el_input, {
                                    placeholder: "身份证号",
                                    modelValue: scope.row.idCard,
                                    "onUpdate:modelValue": ($event: any) => ((scope.row.idCard) = $event)
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                ]))
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_table_column, {
                          label: "操作",
                          width: "70"
                        }, {
                          default: _withCtx((scope) => [
                            _createElementVNode("div", _hoisted_25, [
                              _createElementVNode("p", {
                                class: "link",
                                onClick: ($event: any) => (_ctx.onFileEdit(scope.$index))
                              }, _toDisplayString(_ctx.editIndex === scope.$index
                                                ? "保存"
                                                : "编辑"), 9, _hoisted_26)
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["data", "row-class-name"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_pagination, {
                  class: "pagination",
                  layout: "prev, pager, next",
                  onCurrentChange: _ctx.handleCurrentChange,
                  "page-size": 100,
                  total: _ctx.userList.length
                }, null, 8, ["onCurrentChange", "total"])
              ]),
              _createElementVNode("div", _hoisted_27, [
                _cache[25] || (_cache[25] = _createElementVNode("div", { class: "info-title" }, " 确认信息 ", -1)),
                _createElementVNode("div", _hoisted_28, [
                  _cache[22] || (_cache[22] = _createElementVNode("div", { class: "info-left" }, " 附件名称： ", -1)),
                  _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.fileList[0].name), 1)
                ]),
                _createElementVNode("div", _hoisted_30, [
                  _cache[23] || (_cache[23] = _createElementVNode("div", { class: "info-left" }, " 总人数： ", -1)),
                  _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.userList.length) + "人", 1)
                ]),
                _createElementVNode("div", _hoisted_32, [
                  _cache[24] || (_cache[24] = _createElementVNode("div", { class: "info-left" }, " 总金额： ", -1)),
                  _createElementVNode("div", _hoisted_33, " ￥" + _toDisplayString((_ctx.userList.length * _ctx.planPrice).toFixed(2)), 1)
                ]),
                _createElementVNode("div", _hoisted_34, [
                  _createElementVNode("button", {
                    class: "btn-white",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onReset()))
                  }, " 重新选择 "),
                  _createElementVNode("button", {
                    class: "btn",
                    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onAddInsurePay && _ctx.onAddInsurePay(...args)))
                  }, " 确认添加 ")
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.whichStep === 2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
              _cache[29] || (_cache[29] = _createElementVNode("img", {
                src: _imports_0,
                alt: "",
                class: "result-success"
              }, null, -1)),
              _cache[30] || (_cache[30] = _createElementVNode("div", { class: "result-title" }, " 提交投保成功 ", -1)),
              _createElementVNode("button", {
                class: "btn",
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onCloseEject && _ctx.onCloseEject(...args)))
              }, "返回列表"),
              _createElementVNode("div", _hoisted_36, [
                _createElementVNode("div", _hoisted_37, [
                  _cache[26] || (_cache[26] = _createElementVNode("div", { class: "info-left" }, " 附件名称： ", -1)),
                  _createElementVNode("div", _hoisted_38, _toDisplayString(_ctx.fileList[0].name), 1)
                ]),
                _createElementVNode("div", _hoisted_39, [
                  _cache[27] || (_cache[27] = _createElementVNode("div", { class: "info-left" }, " 总人数： ", -1)),
                  _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.userList.length) + "人", 1)
                ]),
                _createElementVNode("div", _hoisted_41, [
                  _cache[28] || (_cache[28] = _createElementVNode("div", { class: "info-left" }, " 总金额： ", -1)),
                  _createElementVNode("div", _hoisted_42, " ￥" + _toDisplayString((_ctx.userList.length * _ctx.planPrice).toFixed(2)), 1)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["onClose"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.isFormError,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isFormError) = $event)),
      width: "450px",
      title: "名单格式错误人员",
      center: "",
      onClose: _ctx.onFormError
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_43, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileFormatErroe, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "row row-uninsured",
              key: index
            }, [
              _createElementVNode("div", null, "姓名：" + _toDisplayString(item.name) + " 身份证：(" + _toDisplayString(item.code) + ")", 1)
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "onClose"])
  ], 64))
}