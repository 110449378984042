import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"
import _imports_0 from '../assets/leave.png'
import _imports_1 from '../assets/chart/team-chart-bar-blue.png'
import _imports_2 from '../assets/decline.png'
import _imports_3 from '../assets/increase.png'
import _imports_4 from '../assets/chart/team-chart-line-green.png'
import _imports_5 from '../assets/long-insure/dispatch.png'
import _imports_6 from '../assets/long-insure/report.png'


const _hoisted_1 = { class: "header-box" }
const _hoisted_2 = { class: "block block-active" }
const _hoisted_3 = { class: "block-content row amount-box" }
const _hoisted_4 = { class: "box" }
const _hoisted_5 = { class: "row align-start" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "balance" }
const _hoisted_8 = { class: "number" }
const _hoisted_9 = { class: "box" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "" }
const _hoisted_12 = { class: "align-items balance" }
const _hoisted_13 = { class: "number" }
const _hoisted_14 = {
  key: 0,
  src: _imports_2,
  class: "decline-img",
  alt: ""
}
const _hoisted_15 = {
  key: 1,
  src: _imports_3,
  class: "decline-img",
  alt: ""
}
const _hoisted_16 = { class: "block block-content" }
const _hoisted_17 = { class: "block-content row nav box box-active" }
const _hoisted_18 = { class: "container block block-alone" }
const _hoisted_19 = { class: "header" }
const _hoisted_20 = { class: "row header-info-box" }
const _hoisted_21 = { class: "insure-record" }
const _hoisted_22 = { class: "record-i" }
const _hoisted_23 = { class: "num" }
const _hoisted_24 = { class: "record-i" }
const _hoisted_25 = { class: "num" }
const _hoisted_26 = { class: "record-i" }
const _hoisted_27 = { class: "num" }
const _hoisted_28 = { class: "row screen-box" }
const _hoisted_29 = { class: "search" }
const _hoisted_30 = { class: "align-items" }
const _hoisted_31 = { class: "search" }
const _hoisted_32 = { class: "table" }
const _hoisted_33 = ["onClick"]
const _hoisted_34 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_export_button = _resolveComponent("export-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_batch_insure_vue = _resolveComponent("batch-insure-vue")!
  const _component_insureTeamCompany = _resolveComponent("insureTeamCompany")!
  const _component_popupRecharge = _resolveComponent("popupRecharge")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.balanceObj.accountBalance), 1)
                ]),
                _createElementVNode("div", {
                  class: "key align-items point",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (
                                _ctx.$router.push({
                                    path: '/insure/team/bill'
                                })
                                ))
                }, _cache[14] || (_cache[14] = [
                  _createTextVNode(" 账号余额(元) "),
                  _createElementVNode("img", {
                    class: "leave-img",
                    src: _imports_0,
                    alt: ""
                  }, null, -1)
                ]))
              ]),
              _createElementVNode("button", {
                class: "mini-btn recharge-btn",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isRecharge = true))
              }, "充值")
            ]),
            _cache[15] || (_cache[15] = _createElementVNode("div", null, [
              _createElementVNode("img", {
                class: "img-chart",
                src: _imports_1
              })
            ], -1))
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.balanceObj.allEnsureCount), 1),
                  (_ctx.balanceObj.escalating < 0)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_14))
                    : (_openBlock(), _createElementBlock("img", _hoisted_15)),
                  _createElementVNode("div", {
                    class: _normalizeClass(['font-base', _ctx.balanceObj.escalating < 0 ? 'color-green' : 'color-red'])
                  }, _toDisplayString(_ctx.balanceObj.escalating) + "% ", 3)
                ]),
                _createElementVNode("div", {
                  class: "key align-items point",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (
                                _ctx.$router.push({
                                    path: '/insure/team/worker'
                                })))
                }, _cache[16] || (_cache[16] = [
                  _createTextVNode(" 当前在保人数(人) "),
                  _createElementVNode("img", {
                    class: "leave-img",
                    src: _imports_0,
                    alt: ""
                  }, null, -1)
                ]))
              ])
            ]),
            _cache[17] || (_cache[17] = _createElementVNode("img", {
              class: "img-chart-active",
              src: _imports_4
            }, null, -1))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _cache[21] || (_cache[21] = _createElementVNode("div", { class: "block-header" }, [
          _createElementVNode("div", { class: "block-title" }, "便捷导航"),
          _createElementVNode("div", { class: "block-more" })
        ], -1)),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", {
            class: "nav-item point",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onAddCompany && _ctx.onAddCompany(...args)))
          }, _cache[18] || (_cache[18] = [
            _createElementVNode("img", { src: _imports_5 }, null, -1),
            _createElementVNode("div", null, "添加派遣单位", -1)
          ])),
          _createElementVNode("div", {
            class: "nav-item point",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (
                    _ctx.$router.push({
                        path: '/insure-team-report'
                    })
                    ))
          }, _cache[19] || (_cache[19] = [
            _createElementVNode("img", { src: _imports_6 }, null, -1),
            _createElementVNode("div", null, "报案理赔", -1)
          ])),
          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "nav-item point opacity" }, [
            _createElementVNode("img", { src: _imports_6 }),
            _createElementVNode("div", null, "报案理赔1")
          ], -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createVNode(_component_el_tabs, { "model-value": "detail" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _cache[27] || (_cache[27] = _createElementVNode("div", { class: "title" }, "投保扣费记录", -1)),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _cache[22] || (_cache[22] = _createTextVNode(" 投保次数： ")),
                  _createElementVNode("span", _hoisted_23, "¥" + _toDisplayString(_ctx.insureCount.insureProjectCount), 1)
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _cache[23] || (_cache[23] = _createTextVNode(" 投保人次： ")),
                  _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.insureCount.policyPeopleCount), 1),
                  _cache[24] || (_cache[24] = _createTextVNode(" 次 "))
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _cache[25] || (_cache[25] = _createTextVNode(" 费用总计： ")),
                  _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.insureCount.amount || '0'), 1),
                  _cache[26] || (_cache[26] = _createTextVNode(" 元 "))
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("div", _hoisted_29, [
              _createVNode(_component_el_date_picker, {
                modelValue: _ctx.projectTime,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.projectTime) = $event)),
                type: "daterange",
                class: "date-solt point",
                "unlink-panels": "",
                "range-separator": "至",
                "start-placeholder": "生效日期",
                "end-placeholder": "截止日期",
                format: "YYYY-MM-DD",
                "value-format": "YYYY-MM-DD",
                style: {"width":"241px","margin-right":"20px"}
              }, null, 8, ["modelValue"]),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                placeholder: "请输入派遣单位名称",
                class: "input search-input",
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.searchText) = $event)),
                onKeydown: _cache[7] || (_cache[7] = _withKeys(
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)), ["enter"]))
              }, null, 544), [
                [_vModelText, _ctx.searchText]
              ]),
              _createElementVNode("button", {
                class: "btn",
                onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)))
              }, "搜索"),
              _createElementVNode("button", {
                class: "btn-white",
                onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.onReset && _ctx.onReset(...args)))
              }, "重置")
            ]),
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("div", _hoisted_31, [
                _createVNode(_component_export_button, {
                  style: {"width":"initial"},
                  onExport: _ctx.onExportDetail
                }, {
                  default: _withCtx(() => _cache[28] || (_cache[28] = [
                    _createTextVNode("导出明细")
                  ])),
                  _: 1
                }, 8, ["onExport"]),
                _createVNode(_component_export_button, { onExport: _ctx.onExport }, null, 8, ["onExport"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_32, [
            _createVNode(_component_el_table, {
              data: _ctx.tableData,
              style: {"width":"100%"},
              "header-cell-style": { background: '#FBFBFD' },
              "header-row-style": { color: '#202536' },
              "row-style": { color: '#5E617D' },
              onSelectionChange: _ctx.handleSelectionChange
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  type: "index",
                  label: "序号"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "serveCompanyName",
                  label: "派遣单位名",
                  width: "200px"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "comboName",
                  label: "投保方案"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "policyPeopleCount",
                  label: "人数"
                }),
                _createVNode(_component_el_table_column, {
                  width: "200px",
                  prop: "createTime",
                  label: "生效日期"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "amount",
                  label: "保费"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "insureRate",
                  label: "费率"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(" +" + _toDisplayString(scope.row.insureRate * 100) + "% ", 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  prop: "createPort",
                  label: "投保方式"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(scope.row.createPort === 1
                                    ? 'PC投保'
                                    : '手机投保'), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, { label: "操作" }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("p", {
                      style: {"color":"#1989FA","float":"left"},
                      class: "point",
                      onClick: ($event: any) => (
                                _ctx.$router.push({
                                    path:
                                        '/insure/deduction/detail/' +
                                        scope.row.id
                                })
                                )
                    }, " 查看详情 ", 8, _hoisted_33),
                    _createElementVNode("p", {
                      style: {"color":"#1989FA","margin-left":"65px"},
                      class: "point",
                      onClick: ($event: any) => (_ctx.onSingleExport(scope.row.id))
                    }, " 导出 ", 8, _hoisted_34)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data", "onSelectionChange"]),
            _createVNode(_component_el_pagination, {
              class: "pagination",
              currentPage: _ctx.pageNum,
              "onUpdate:currentPage": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.pageNum) = $event)),
              "page-size": _ctx.pageSize,
              "onUpdate:pageSize": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.pageSize) = $event)),
              "page-sizes": [10, 30, 50, 100],
              layout: "total, sizes, prev, pager, next, jumper",
              total: _ctx.total,
              onSizeChange: _ctx.handleSizeChange,
              onCurrentChange: _ctx.insureDeductionList
            }, null, 8, ["currentPage", "page-size", "total", "onSizeChange", "onCurrentChange"])
          ])
        ]),
        _: 1
      }),
      (_ctx.isInsure)
        ? (_openBlock(), _createBlock(_component_batch_insure_vue, {
            key: 0,
            onComplete: _ctx.insureComplete,
            onClose: _ctx.onCloseCallback
          }, null, 8, ["onComplete", "onClose"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_insureTeamCompany, { ref: "teamCompany" }, null, 512)
    ]),
    (_ctx.isRecharge)
      ? (_openBlock(), _createBlock(_component_popupRecharge, {
          key: 0,
          lockArr: ['微信充值', '支付宝充值'],
          onClose: _cache[12] || (_cache[12] = ($event: any) => (_ctx.isRecharge = false)),
          useWay: "InsureRechargeGsc",
          openBank: _ctx.openBank,
          bankNo: _ctx.bankNo,
          bankName: _ctx.bankName,
          onDetermine: _cache[13] || (_cache[13] = ($event: any) => (_ctx.isRecharge = false)),
          dataTitle: "团意保"
        }, null, 8, ["openBank", "bankNo", "bankName"]))
      : _createCommentVNode("", true)
  ], 64))
}