
import global from '@/utils/global'
import { defineComponent } from 'vue'
import { getDispatchExamine, getDispatchList, cancelDispatchExamine, deleteDispatchExamine, getCompanyList } from '@/api/long-insure'
import { BillDetailDto, BillDto } from '@/api/bill'
import batchPayroll from '@/components/batch-payroll.vue'
import { insureDispatchList, insureDispatchDelete, addDispatch } from '@/api/insure-deduction'
export default defineComponent({
    name: 'InsureTeamCompany',
    components: {
        batchPayroll
    },
    data () {
        return {
            Authorization: 'Bearer' + ' ' + global.token,
            uploadAction:
                process.env.VUE_APP_BASE_URL +
                '/rest/period-insur/serve-company/upload', // 上传路径
            isBatchPay: false,
            pageSize: 10,
            pageNum: 1,
            total: 0,
            tableData: [] as BillDetailDto[],
            searchText: '',
            searchValue: '',
            detailInfo: {} as BillDto,
            selects: [] as string[],
            centerDialogVisible: false, // 弹窗
            fileSize: '',
            fileUrl: '',
            isfileLengt: true,
            dispatchUnit: '', // 派遣单位
            companyList: [] as any, // 所有单位
            companyId: '', // 选择的单位id
            remarksInput: '',
            fileList: [] as {fileSize: string, fileType: string, fileUrl: string, uid:string}[],
            revealFileList: [] as any,
            audioDuration: 0 as any
        }
    },
    created () {
        this.getPayrollList()
        this.getCompanyList()
    },
    methods: {
        // 打开添加派遣单位弹窗
        onAddCompany () {
            this.centerDialogVisible = true
        },
        // 获取所有单位列表
        async getCompanyList () {
            const result = await getCompanyList()
            console.log(this.companyList, 'result获取所有单位列表')
            this.companyList = result.list
        },
        // 取消 派遣段位 审核
        async cancelDispatchExamine (id:string) {
            const result = await cancelDispatchExamine({ id: id })
            await this.getPayrollList()
            this.$message.success('取消成功！')
        },
        // 删除派遣单位
        async deleteDispatchExamine (id: string) {
            console.log(id, 'id')
            await insureDispatchDelete({ id: id })
            await this.getPayrollList()
            this.$message.success('删除成功！')
        },
        async getPayrollList () {
            console.log('点击啦', this.pageSize, this.pageNum, this.searchText)
            const result = await insureDispatchList({
                pageSize: this.pageSize,
                pageNum: this.pageNum,
                serveCompanyName: this.searchValue
            })
            this.total = result.totalCount
            this.tableData = result.list
        },
        handleSizeChange () {
            this.pageNum = 1
            this.getPayrollList()
        },
        search () {
            this.searchValue = this.searchText
            this.pageNum = 1
            this.getPayrollList()
        },
        onReset () {
            this.searchText = ''
            this.search()
        },
        handleSelectionChange (selects: { id: string }[]) {
            this.selects = selects.map(item => item.id)
        },
        fileSuccess (response:{code:number, msg:string, data:{fileSize:string, fileUrl:string}}, file: any, fileList: any) {
            if ((file.size / 1024 / 1024) > 30) {
                this.$message.error('视频不可大于30mb')
                console.log(this.revealFileList, 'revealFileList')
                return
            }
            if (this.fileList.length >= 3) {
                this.$message.error('最多支持上传三个')
                return
            }
            if (response.code === 500) {
                this.$message.error(response.msg)
                return
            }
            const arr = []
            if (/video/.test(file.raw.type)) {
                arr.push({
                    fileSize: file.response.data.fileSize,
                    fileType: 'video',
                    fileUrl: file.response.data.fileUrl,
                    uid: file.uid
                })
            }
            if (/image/.test(file.raw.type)) {
                arr.push({
                    fileSize: file.response.data.fileSize,
                    fileType: 'image',
                    fileUrl: file.response.data.fileUrl,
                    uid: file.uid
                })
            }
            this.revealFileList.push({
                url: file.response.data.fileUrl,
                name: file.name,
                uid: file.uid
            })
            this.fileList.push(...arr)
            this.$message.success('上传成功')
        },
        fileEerror () {
            this.$message.error('文件上传失败')
        },
        fileRemove (uid:string) {
            console.log(uid)
            this.fileList = this.fileList.filter(item => item.uid !== uid)
            this.revealFileList = this.revealFileList.filter((item: { uid: string }) => item.uid !== uid)
            console.log(this.fileList, uid, 'event')
        },
        // 被派遣单位
        async addDispatchUnit () {
            console.log(this.companyId, 'companyId')
            if (!this.remarksInput) {
                this.$message.error('请输入申请理由')
                return
            }
            const arr = this.fileList.filter(item => {
                if (/.(mp4|mp3)$/i.test(item.fileUrl)) {
                    return true
                }
            })
            if (arr.length === 0) {
                this.$message.error('请至少上传一个视频')
                return
            }
            const result = await addDispatch({
                serveCompanyId: Number(this.companyId),
                params: {
                    photos: this.fileList
                },
                remark: this.remarksInput,
                applySource: 1
            })
            console.log(result, 'result')
            this.centerDialogVisible = false
            await this.getPayrollList()
            this.$message.success('提交被派遣单位成功')
        }
    }
})
